import richText from "utilities/richText"
import Image from "components/Image"
import LazyVideo from "components/LazyVideo"

export default function Hero({ blok }) {
  return (
    <section className="relative z-10">
      {blok.video.filename ? (
        <LazyVideo
          className="pointer-events-none relative w-full object-cover lg:h-[650px]"
          poster={blok.image.filename}
          sources={[
            { src: blok.video.filename, type: "video/webm; codecs=av01.0.05M.08" },
            { src: blok.video_fallback.filename, type: "video/mp4" },
          ]}
          autoPlay
          playsInline
          muted
          loop
        />
      ) : (
        <div className="inset-0 w-full">
          <Image
            src={blok.image.filename}
            alt={blok.image.alt}
            width={1440}
            height={696}
            className="relative h-[250px] w-full object-cover object-[62%_50%] md:h-[530px]"
          />
        </div>
      )}
      <div className="inset-0 flex items-start justify-center md:absolute md:mt-18">
        <div className="w-full grid-cols-[1fr_1280px_1fr] md:grid">
          <div className="h-full bg-springWood md:bg-springWood/[.90]"></div>
          <div className="hero-content col-start-2 flex justify-center bg-springWood/[.90] p-6 py-9 md:max-w-[26rem] md:rounded-br-4xl">
            <div className="prose-h1:leading-10 prose-h1:text-teal-dark prose-p:py-5 prose-p:text-m2 prose-p:leading-6 prose-h1:lg:leading-[50px]">
              {richText(blok.content)}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
